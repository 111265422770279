import React from 'react'
import { themeStyles, colors, metrics } from '../utils/theme'
import withConfig from '../wrappers/withConfig'
import { rhythm } from '../utils/typography'

const Promo = ({ phone, style, tagline }) =>  {
  return (
    <div style={{ marginTop: metrics.defaultMargin, marginBottom: metrics.defaultMargin, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '200px', position: 'relative', backgroundColor: 'rgba(1,1,1,0)', ...style}}>
      <h2 css={{ textAlign: 'center', ...themeStyles.textPadding, zIndex: 2, margin: 0}}>
        {tagline}
      </h2>
      <div css={{ width: '100%', maxWidth: rhythm(10), borderTop: `5px solid ${colors.gold}`, margin: `${rhythm(1)} 0`}}/>
      <a href={`tel:${phone}`} css={{zIndex: 2, textDecoration: 'none'}}>
        <h1 css={{zIndex: 2, margin: 0, ...themeStyles.blueText}}>
          {phone}
        </h1>
      </a>
    </div>
  )
}

export default withConfig(Promo)
