export const locationsMeta = {
    'Salt Lake City':{
        'title':'Best Carpet Cleaning, Pressure Washing, Window & Lawn Maintenance in Salt Lake City',
        'description':'Discover top-notch janitorial, carpet cleaning, pressure washing, window washing, and lawn maintenance services in Salt Lake City, UT with Blumont Services.',
        'keywords':'janitorial services salt lake city, carpet cleaning salt lake city, Pressure Washing salt lake city, Window Washing salt lake city, Lawn Maintenance salt lake city UT',
        'url':'https://www.blumontservices.com/locations/salt-lake-city/'
    },
    'West Valley City':null,
    'Provo':null,
    'West Jordan':{
        'title':'Quality Consumable Supplies in West Jordan, UT | Blumont Services',
        'description':'Discover top-notch consumable supplies in West Jordan, UT at Blumont Services. Reliable products for your needs, sourced for excellence and satisfaction.',
        'keywords':'Consumable Supplies West Jordan UT',
        'url':'https://www.blumontservices.com/locations/west-jordan/'
    },
    'Orem':{
        'title':'Expert Window Washing Services in Orem, UT | Blumont Services',
        'description':'For professional cleaning services in Orem, UT, trust Blumont Services. We specialize in window washing and comprehensive cleaning solutions for homes and businesses.',
        'keywords':'Window Washing Orem UT, cleaning services orem',
        'url':'https://www.blumontservices.com/locations/orem'
    },
    'Sandy':{
        'title':'Blind Cleaning Experts in Sandy, UT | Blumont Services',
        'description':'Discover top-notch blind cleaning services in Sandy, UT with Blumont Services. Our experts ensure pristine cleanliness for a brighter, healthier home environment.',
        'keywords':'Blind Cleaning Sandy UT',
        'url':'https://www.blumontservices.com/locations/sandy'
    },
    'St George':{
        'title':'Window Washing in St. George, Utah | Blumont Services',
        'description':'Elevate your view with professional window washing in St. George, Utah. Trust Blumont Services for sparkling clean windows and exceptional results. Contact us today!',
        'keywords':'window washing st george, window washing st george utah',
        'url':'https://www.blumontservices.com/locations/st-george/'
    },
    'Ogden':null,
    'Layton':null,
    'South Jordan':null,
    'Lehi':null,
    'Millcreek':null,
    'Taylorsville':null,
    'Herriman':null,
    'Logan':null,
    'Murray':null,
    'Draper':null,
    'Riverton':null,
    'Bountiful':null,
    'Eagle Mountain':{
        'title':'Get Carpet Cleaning & Lawn Care in Eagle Mountain | Blumont Services',
        'description':'Experience top-tier carpet cleaning and lawn care in Eagle Mountain, Utah. Choose Blumont Services for a cleaner, greener, and healthier home environment.',
        'keywords':'carpet cleaning eagle mountain, lawn care eagle mountain utah',
        'url':'https://www.blumontservices.com/locations/eagle-mountain '
    },
    'Spanish Fork':null,
    'Roy':null,
    'Pleasant Grove':null,
    'Kearns':null,
    'Saratoga Springs':null,
    'Tooele':null,
    'Midvale':null,
    'Cedar City':{
        'title':'Carpet Cleaning & Pest Control in Cedar City | Blumont Services',
        'description':'Blumont Services offers top-notch carpet cleaning and pest control in Cedar City. Trust our experts for a cleaner, pest-free home. Call now for reliable service!',
        'keywords':'carpet cleaning cedar city, cedar city pest control experts',
        'url':'https://www.blumontservices.com/locations/cedar-city/'
    },
    'American Fork':null,
    'Cottonwood Heights':null,
    'Springville':{
        'title':'Carpet Cleaning & Lawn Maintenance in Springville | Blumont Services',
        'description':'Blumont Services offers top-notch carpet cleaning and lawn maintenance in Springville. Trust our experts for a cleaner home and well-maintained lawn.',
        'keywords':'carpet cleaning springville, Lawn Maintenance Springville',
        'url':'https://www.blumontservices.com/locations/springville/'
    },
    'Kaysville':null,
    'Clearfield':null,
    'Syracuse':null,
    'Holladay':null,
    'Washington':null,
    'Magna':null,
    'Farmington':null,
    'South Salt Lake':null,
    'Clinton':null,
    'North Ogden':null,
    'North Salt Lake':null,
    'Hurricane':null,
    'Highland':null,
    'Payson':null,
    'Brigham City':{
        'title':'Pest Control Brigham City UT | Blumont Services',
        'description':'Blumont Services offers top-notch pest control in Brigham City, Utah. Experience professional carpet cleaning services in Brigham City with us. Your trusted source for Brigham City pest control.',
        'keywords':'pest control brigham city utah, brigham city pest control, carpet cleaning in brigham city',
        'url':'https://www.blumontservices.com/locations/brigham-city/'
    },
    'Centerville':null,
    'Bluffdale':null,
    'Heber':null,
}