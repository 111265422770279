export const services = [
  "Janitorial Services",
  "Consumable Supplies",
  "Window Washing",
  "Blind Cleaning",
  "Commercial Carpet Cleaning",
  "Epoxy Floor Cleaning",
  "Strip and Wax Floor Care",
  "Pest Control",
  "Lawn Maintenance",
  "Pressure Washing",
]

export const carpetServices = [
  "Janitorial Services",
  "Consumable Supplies",
  "Window Washing",
  "Blind Cleaning",
  "Commercial Carpet Cleaning",
  "Epoxy Floor Cleaning",
  "Strip and Wax Floor Care",
  "Pest Control",
  "Lawn Maintenance",
  "Pressure Washing",
]