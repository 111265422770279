import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import { themeStyles, colors, presets, metrics } from '../utils/theme'
import _ from 'lodash'
import PropTypes from "prop-types"
import PageTitle from '../components/PageTitle'
import { industries } from '../utils/industries'
import { services } from '../utils/services'
import { locationsMeta } from '../utils/locationsMeta'
import Promo from '../components/Promo'
import withConfig from '../wrappers/withConfig'
import MainHelmet from '../components/MainHelmet'
import { useLayoutEffect } from 'react'


const styles = {

}

const LocationPage = ({
  data,
  location,
  keyServiceName,
  keyServiceNameSynonym,
  keyServiceNameSynonym2,
  keyServiceNoun,
  keyServiceActiveNoun,
  promoTagline,
  ...otherProps
}) => {
  const { locationName } = _.get(data, 'allSitePage.edges.0.node.context') || {}
  const title = `${keyServiceName} in ${locationName}`
  const description = `Your trusted partner for ${keyServiceName} in ${locationName}. Get in touch for expert ${keyServiceNameSynonym}.`
  return (
    <Layout>
      <MainHelmet
        title = {locationsMeta[locationName] != null ? locationsMeta[locationName].title : title}
        description = {locationsMeta[locationName] != null ? locationsMeta[locationName].description : description}
        keywords = {locationsMeta[locationName] != null ? locationsMeta[locationName].keywords : ""}
      />
      <PageTitle 
        fontSize={2} 
        title={locationsMeta[locationName] != null ? locationsMeta[locationName].title : title} 
        description = {locationsMeta[locationName] != null ? locationsMeta[locationName].description : description}
        keywords = {locationsMeta[locationName] != null ? locationsMeta[locationName].keywords : ""}
      />
      <div css={[themeStyles.contentWidth]}>
        <div css={themeStyles.textPadding}>
          <h2>{locationName}, we're here for you.</h2>
          <p>
            It would be our pleasure to serve you as you serve the people of {locationName}.
  You will find, as many others in the greater {locationName} community have, that BluMont's {keyServiceNameSynonym} offer exceptional value, professionalism, and expertise.
          </p>
          <p>
            We also hold our {keyServiceNameSynonym2} to the highest standard.
            And we back it by the BluMont Gaurantee: If you're not satisfied by your service in {locationName}, the day's cleaning is on us.
          </p>
          <Promo tagline={promoTagline} style={{backgroundColor: colors.lightGrey}} />
          <p>
            Here are a few examples of industries we are experienced in serving in {locationName}:
          </p>
          <ul>
            {_.map(industries, i => (<li>{i} {keyServiceName} in {locationName}, UT</li>))}
          </ul>
          <p>
            We'd be delighted at the chance to {keyServiceActiveNoun} in {locationName} or anywhere else in Utah.
          </p>
          <p>
            We offer the following specialized services in {locationName} as well as many more:
          </p>
          <ul>
            {_.map(services, s => (<li>{s} in {locationName}, UT</li>))}
          </ul>
        </div>
      </div>
    </Layout>
  )
}

const OuterComponent = withConfig(LocationPage)

LocationPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default OuterComponent

export const pageQuery = graphql`
  query LocationTemplate($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            locationName
          }
        }
      }
    }
  }
`
